<template>
	<div class="flex">
		<section class="content-main">
			<el-form :model="form" ref="form" label-width="140px">
				<el-form-item label="用户名">
					<p>{{form.username}}</p>
				</el-form-item>
				<el-form-item label="店铺名称" prop="name">
					<el-input v-model.trim="form.name" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="域名" prop="my_host">
					<el-input v-model="form.my_host" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="扩展域名" prop="my_host_alias">
					<el-input type="textarea" v-model="form.my_host_alias" :autosize="{ minRows: 3}" style="width: 300px"></el-input>
				</el-form-item>
				<el-form-item label="店铺联系方式">
					<p>微信：{{form.true_name}} 支付宝：{{form.true_name}} 手机号码{{form.true_name}}</p>
				</el-form-item>

				<el-form-item label="支付宝" prop="alipay_id">
					<el-input v-model="form.alipay_id"   class="short-width" ></el-input>
				</el-form-item>

				<el-form-item label="联系手机号" prop="mobile_id">
					<el-input v-model="form.mobile_id" placeholder="该手机号用于彩民沟通" class="short-width" :maxlength="11"></el-input>
				</el-form-item>

				<el-form-item label="绑定手机号" prop="mobile">
					<el-input v-model="form.mobile" placeholder="该手机号用于平台" class="short-width" :maxlength="11"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<p>{{form.email}}</p>
				</el-form-item>
				<el-form-item label="地区">
					<el-select class="mr10" v-model="form.province_id" placeholder="请选择省份">
						<el-option v-for="i in provinceList" :key="i.id" :label="i.name" :value="i.id">
						</el-option>
					</el-select>
					<el-select v-if="form.province_id" v-model="form.city_id" placeholder="请选择县市">
						<el-option v-for="i in cityList" :key="i.id" :label="i.name" :value="i.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="支付人姓名">
					<p>{{form.payer}}</p>
				</el-form-item>
				<el-form-item label="体彩代销证编号">
					<el-input v-model="form.sport_lottery_seq" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="福彩代销证编号">
					<el-input v-model="form.welfare_lottery_seq" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="代销证">
					<div class="flex"> 
						<!-- <div class="avatar" v-for="(item,index) in form.agent_certificate_pic" :key="index">
							<img v-if="item.url" :src="item.url" @click="big_img = item.url">
							<img v-else :src="item" @click="big_img = item">
						</div> -->
						<el-upload list-type="picture-card" :limit='4' :action="uploadurl" :data="dataToken" :on-preview="handlePictureCardPreview"
						 :on-remove="handleRemove" :on-success="uploaderCertificateSuccess" :on-exceed="exceed" :file-list="form.agent_certificate_pic">
							<i class="el-icon-plus"></i>
						</el-upload>
					</div>
				</el-form-item>
				<el-form-item label="体彩代销证到期时间">
					<el-date-picker v-model="form.sport_lottery_expire" type="date" value-format="yyyy-MM-dd" placeholder="体彩代销证到期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="福彩代销证到期时间">
					<el-date-picker v-model="form.welfare_lottery_expire" type="date" value-format="yyyy-MM-dd" placeholder="福彩代销证到期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="代销者">
					<div v-if="form.identity_pic.contrary" class="flex">
						<el-upload class="avatar-uploader" :action="uploadurl" :data="dataToken" :show-file-list="false" :on-success="uploaderIdentityFrontSuccess">
							<img v-if="form.identity_pic.front" :src="form.identity_pic.front" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon">正面</i>
							<div v-if="form.identity_pic.front" @click.stop="handlePictureCardPreview(form.identity_pic.front, 1)" class="seeBigImg">放大</div>
						</el-upload>
						<el-upload class="avatar-uploader" :action="uploadurl" :data="dataToken" :show-file-list="false" :on-success="uploaderIdentityContrarySuccess">
							<img v-if="form.identity_pic.contrary" :src="form.identity_pic.contrary" class="avatar">
							<i v-else class="el-icon-plus avatar-uploader-icon">反面</i>
							<div v-if="form.identity_pic.contrary" @click.stop="handlePictureCardPreview(form.identity_pic.contrary, 1)" class="seeBigImg">放大</div>
						</el-upload>

						<!-- <div class="avatar">
							<img :src="form.identity_pic.contrary" @click="big_img = form.identity_pic.contrary">
						</div>
						<div class="avatar">
							<img :src="form.identity_pic.front" @click="big_img = form.identity_pic.front">
						</div> -->
					</div>
					<div v-else class="flex">
						<div class="avatar" v-for="(item,index) in form.identity_pic" :key="index">
							<img :src="item" @click="big_img = item">
						</div>
					</div>
				</el-form-item>				
				<el-form-item label="代销者姓名">
					<el-input v-model="form.contact" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="代销者身份证编号">
					<el-input v-model="form.identity_num" class="short-width"></el-input>
				</el-form-item>
				<el-form-item label="推广人/渠道">
					<p>{{form.true_name}}</p>
				</el-form-item>
				<el-form-item label="店铺状态">
					<!-- <p>{{form.store_status_desc}}</p> -->
					<el-select v-model="form.store_status" placeholder="请选择店铺状态">
						<el-option v-for="i in statusList" :key="i.value" :label="i.text" :value="i.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="店铺当前版本">
					<p v-if="form.server_level == 1">标准版</p><p v-else>高级版</p>
				</el-form-item>
				<!-- <el-form-item label="店铺开通时间">
					<el-date-picker v-model="form.create_at" type="date" placeholder="店铺开通时间"></el-date-picker>
				</el-form-item> -->
				<el-form-item label="店铺到期时间">
					<el-date-picker v-model="form.server_end" type="date" placeholder="店铺到期时间"></el-date-picker>
				</el-form-item>
				<el-form-item label=" ">
					<el-button type="primary" @click.native="saveSubmit">提交修改</el-button>
					<el-button @click.native="goBack">取消</el-button>
				</el-form-item>
			</el-form>
		</section>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt="">
		  </el-dialog>
		<div class="flex1">
			<img v-if="big_img" :src="big_img" class="avatar1">
		</div>
	</div>
</template>

<script>
	import { getStoreInfo,updateStoreInfo,showProvince,showCity,getUploadToken } from '../../api/api';
	export default {
		data() {
			//验证电话号码格式
			var validatePhone = (rule, value, callback) => {
				if(value === ''){
					callback(new Error('手机号码不能为空'));
				}else if(!(/^1[3456789]\d{9}$/.test(value))) {
					callback(new Error('请输入正确的手机号码格式'));
				} else {
					callback();
				}
			}
			return {
				//编辑ID
				aID: this.$route.query.id,
				statusList: [
					{ text: '未生效', value: 1 },
					{ text: '已生效', value: 2 },
					{ text: '已过期', value: 3 },
					{ text: '禁止登录', value: 4 },
					{ text: '无法接单', value: 5 },
				],
				//新增界面数据
				form: {
					true_name: '',
					job_num: '',
					group_id: '',
					user_name: '',
					password: '',
					remark: '',
					avatar: '',
					sport_lottery_expire: '',
					welfare_lottery_expire: '',
					role_id: [],
					identity_pic:{
						contrary:'',
						front:''
					}
				},
				provinceList: [],
				cityList: [],
				big_img: '',
				uploadurl: process.env.VUE_APP_Qiniu, //上传地址
				dataToken: {}, //上传文件需要携带的参数
				imgUrlHead: '',
				dialogImageUrl: '',
				dialogVisible: false
			}
		},
		watch:{
			'form.province_id':function(newVal,oldVal){
				if(newVal != oldVal){
					this.getCityList()
				}
			},
		},
		methods: {
			uploaderIdentityFrontSuccess(response) {
				this.form.identity_pic.front = this.imgUrlHead + response.key;
			},
			uploaderIdentityContrarySuccess(response) {
				this.form.identity_pic.contrary = this.imgUrlHead + response.key;
			},
			handlePictureCardPreview(file, type) {
				if(type){
					this.dialogImageUrl = file;
				}else{
					this.dialogImageUrl = file.url;
				}
				this.dialogVisible = true;
			},
			handleRemove(file) {
				var url = file.url;
				this.form.agent_certificate_pic.some((item, index) => {
					if (item.url == url) {
						this.form.agent_certificate_pic.splice(index, 1)
						return true
					}
				})
				console.log(this.form.agent_certificate_pic)
			},
			//上传图片成功回调agent_certificate_pic
			uploaderCertificateSuccess(response, file) {
				console.log('Success', response);
				this.form.agent_certificate_pic.push({
					// name: response.key,
					url: this.imgUrlHead + response.key
				});
			},
			exceed() {
				this.$message.error('代销证不能超过4张')
				// console.log(this.form.agent_certificate_pic)
			},
			//获取上传文件的token
			getUploadTokenM (){
				getUploadToken().then((res) => {
					console.log('getUploadToken', res)
					this.dataToken = {token: res.data.uptoken}
					this.imgUrlHead = 'http://' + res.data.url + '/'
				});
			},
			//获取省份
			getProvinceList(){
				showProvince().then((res) => {
					if(res.code == 1){
						this.provinceList = res.data;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//获取县市
			getCityList() {
				let para = {
					pid: this.form.province_id
				};
				showCity(para).then((res) => {
					if(res.code == 1){
						this.cityList = res.data;
					}else {
						this.$notify({
							title: '错误',
							message: res.msg,
							type: 'error'
						});
					}
				});
			},
			//获取信息
			getInfo() {
				let para = {store_id: this.aID};
				getStoreInfo(para).then((res) => {
					this.form = Object.assign({}, res.data);
					if(this.form.agent_certificate_pic){
						let imgArr = []
						this.form.agent_certificate_pic.forEach(item => {		
							console.log('type', typeof item)
							let obj = {
								status: 'success'
							}
							if(typeof item == 'string'){
								obj.uid = ''
								obj.url = item
							}else{
								obj.uid = item.uid
								obj.url = item.url
							}
							imgArr.push(obj)
						})
						this.form.agent_certificate_pic = imgArr
						console.log('img', imgArr)
					}else{
						this.form.agent_certificate_pic = []
					}
					// this.form.create_at = this.form.create_at*1000
					this.form.server_end = this.form.server_end*1000
				});
			},
			//编辑保存
			saveSubmit: function () {
				//提交表单验证
				console.log('saveSubmit')
				this.$refs.form.validate((valid) => {
					console.log('valid', valid)
					if (valid) {
						this.$confirm('确认提交吗？', '提示', {}).then(() => {
							let imgArr = []
							console.log('`````', this.form.agent_certificate_pic)
							this.form.agent_certificate_pic.forEach(item => {
								imgArr.push(item.url)
							})
							this.Loading = true;
							const para = {
								store_id : this.aID,
								name : this.form.name,
								my_host : this.form.my_host,
								my_host_alias : this.form.my_host_alias,
								mobile : this.form.mobile,
								province_id : this.form.province_id,
								city_id : this.form.city_id,
								contact : this.form.contact,
								identity_num : this.form.identity_num,
								sport_lottery_seq : this.form.sport_lottery_seq,
								welfare_lottery_seq : this.form.welfare_lottery_seq,
								sport_lottery_expire : this.form.sport_lottery_expire,
								welfare_lottery_expire : this.form.welfare_lottery_expire,
								store_status: this.form.store_status,
								agent_certificate_pic: JSON.stringify(imgArr),
								identity_pic: JSON.stringify({
									contrary: this.form.identity_pic.contrary,
									front: this.form.identity_pic.front,
								}),								
								// create_at : this.form.create_at/1000,
								server_end : this.form.server_end/1000
							}
							// let para = Object.assign({}, this.form);
							// para.store_id = this.aID;
							updateStoreInfo(para).then((res) => {
								this.Loading = false;
								if(res.code==1){
									this.$message.success(res.msg);
									this.goBack();
								}else {
									this.$message.error(res.msg);
								}
							});
						});
					}
				});
			},
		},
		mounted() {
			// 获取省份
			this.getInfo()
			this.getProvinceList()
			this.getCityList()
			this.getUploadTokenM ()
		}
	}

</script>
<style scoped>

.flex{
	display: flex;
}
.flex1{
	flex: 1;
	
}
.bigImg{
	display: flex;
	justify-content: center;
	align-content: center;
}
.content-main{
	width: 600px;
}
.avatar {
	width: 160px;
	height: 160px;
	margin-right: 10px;
	border-radius: 8px;
	display: flex;
	justify-content: center;
	align-content: center;
	overflow: hidden;
	background-color: #fefefe;
	border: 1px dashed #cccccc;
}
.avatar img{
	width: auto;
	height: 100%;
}
.avatar1{
	width: 90%;
	height: auto;
	margin: 600px auto 0;
}
.avatar-uploader {
		width: 148px;
		height: 148px;
		display: flex;
		align-items: center;
		margin-right: 15px;
		float: left;
		border-radius: 6px;
		overflow: hidden;
		background-color: #cccccc;
		position: relative;
	}
.seeBigImg{
		position: absolute;
		right: 0px;
		bottom: 0px;
		width: 44px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-top-left-radius: 8px;
		background-color: rgba(0,0,0,0.8);
		color: #ffffff;
	}
</style>
